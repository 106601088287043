import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/system';
import useUserStore from '../state/UserStore';

const LowercaseToggleButton = styled(ToggleButton)({
  textTransform: 'none',
  color: '#939393',
  '&.Mui-selected': {
    color: '#939393',
  },
  fontSize: '16px',
});

interface Props {
  page: string;
}

export default function PageSelector({ page }: Props) {
  const userStore = useUserStore((state) => state);

  if (!userStore.user) {
    return null;
  }

  const handleChange = (event: React.MouseEvent<HTMLElement>, pageSelection: string) => {
    if (pageSelection === 'kiosk' && page !== 'kiosk') {
      window.location.href = '/kiosk';
    } else if (pageSelection === 'portal' && page !== 'portal') {
      window.location.href = '/';
    }
  };

  return (
    <ToggleButtonGroup
      value={page}
      exclusive
      onChange={handleChange}
      sx={{ backgroundColor: 'white' }}
    >
      <LowercaseToggleButton value="portal">Portal</LowercaseToggleButton>
      <LowercaseToggleButton value="kiosk">Kiosk</LowercaseToggleButton>
    </ToggleButtonGroup>
  );
}
