import 'reactflow/dist/style.css';

import { Box, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import TimeAgo from 'react-timeago';
import ReactFlow, {
  Position,
  ReactFlowProvider,
  useEdgesState,
  useNodesInitialized,
  useNodesState,
} from 'reactflow';

import { getSitePowerCapacityQuery } from '../../../../lib/Queries';
import DataComponentError from '../../Shared/DataComponentError';
import Loading from '../../Shared/Loading';
import { loadChilds, nodesOrder } from '../Logic/FormatUtils';
import CustomNode from './CustomNode';

const nodeTypes = {
  custom: CustomNode,
};

/**
 * Generates an array of node objects based on the provided data.
 * @param {Array} data - The data to generate the nodes from.
 * @param {Function} setNodes - The function to set the generated nodes.
 */
export const generateNodes = (data, setNodes, setEdges, timezone) => {
  const sortedData = data.sort((a, b) => {
    const indexA = nodesOrder.indexOf(
      a.scaleos_type || (a.array && a.array[0].scaleos_type.split('_')[0]),
    );
    const indexB = nodesOrder.indexOf(
      b.scaleos_type || (b.array && b.array[0].scaleos_type.split('_')[0]),
    );
    return indexA - indexB;
  });

  let positionX = 20;
  let positionY = 100;
  let loadChildsPosY = sortedData.length <= 3 ? 100 : sortedData.length === 4 ? 50 : 10;
  let sourcePos =
    sortedData.length <= 3
      ? [Position.Right]
      : sortedData.length === 4
        ? [Position.Top, Position.Bottom]
        : [Position.Top, Position.Right, Position.Bottom];
  let sourceIndex = 0;
  let idItem = 1;
  let dataNodes = [];
  let powerDER = 0;
  let powerUtility = 0;

  /*
   * Nodes position (x, y) and parent/child
   */
  for (const item of sortedData) {
    let nestedId = 0;
    let loadChildsPosX = positionX;

    if (Array.isArray(item.array)) {
      let isSourcePos = false;
      for (const nestedItem of item.array) {
        const obj = {
          id: `${idItem.toString()}-${nestedId.toString()}`,
          type: 'custom',
          data: {
            sourcePos: !isSourcePos ? sourcePos[sourceIndex] : 'left',
            nodeType: 'square',
            source: nestedItem.scaleos_type,
            name: nestedItem.scaleos_name.replace('_', ' '),
            power: nestedItem.power,
            percentage: nestedItem.percentage,
            alarms: nestedItem.alarms,
            alarmsCount: nestedItem.alarms_count,
            timezone: timezone,
            ...(nestedItem['soc'] && { soc: nestedItem.soc }),
          },
          position: { x: loadChildsPosX, y: loadChildsPosY },
          child: nestedId === 0 ? true : false,
        };
        isSourcePos = true;
        loadChildsPosX += 80;
        nestedId += 1;
        dataNodes.push(obj);
        powerDER += parseFloat(nestedItem.power);
      }
      sourceIndex += 1;
      loadChildsPosY += 90;
      idItem += 1;
    } else {
      if (loadChilds.includes(item.scaleos_type)) {
        const obj = {
          id: `${idItem.toString()}-${nestedId.toString()}`,
          type: 'custom',
          data: {
            sourcePos: sourcePos[sourceIndex],
            nodeType: 'square',
            source: item.scaleos_type,
            name: item.scaleos_name.replace('_', ' '),
            power: item.power,
            percentage: item.percentage,
            alarms: item.alarms,
            alarmsCount: item.alarms_count,
            timezone: timezone,
            ...(item['soc'] && { soc: item.soc }),
          },
          position: { x: loadChildsPosX, y: loadChildsPosY },
          child: true,
        };
        dataNodes.push(obj);
        loadChildsPosX += 170;
        loadChildsPosY += 90;
        sourceIndex += 1;
        powerDER += parseFloat(item.power);
      } else {
        powerUtility += item.scaleos_type.toLowerCase() === 'utility' ? item.power : 0;
        const obj = {
          id: `${idItem.toString()}-${nestedId.toString()}`,
          type: 'custom',
          data: {
            sourcePos: 'left',
            nodeType: 'square',
            source: item.scaleos_type,
            name: item.scaleos_name.replace('_', ' '),
            power: item.power,
            percentage: item.percentage,
            alarms: item.alarms,
            alarmsCount: item.alarms_count,
            timezone: timezone,
            ...(item['soc'] && { soc: item.soc }),
          },
          position: { x: positionX, y: positionY },
          child: false,
        };
        positionX += 200;
        loadChildsPosX = positionX;
        dataNodes.push(obj);
      }
      idItem += 1;
    }
  }

  const powerSum = powerUtility + powerDER;
  const porcUtility = Math.min(
    Math.max(parseFloat(((powerUtility / powerSum) * 100).toFixed(0)), 0),
    100,
  );
  const porcDER = Math.min(Math.max(parseFloat(((powerDER / powerSum) * 100).toFixed(0)), 0), 100);

  const newDataNodes = dataNodes
    .map((item, index) => {
      if (item.data.source.toLowerCase() === 'utility') {
        const utility = {
          id: `${item.id.replace('-0', '-1')}`,
          type: 'custom',
          data: {
            sourcePos: 'left',
            percentage: porcUtility,
            nodeType: 'piechart',
            source: 'utility-pie',
          },
          position: {
            x: item.position.x + 120,
            y: item.position.y + 17,
          },
          child: false,
        };
        return [item, utility];
      } else if (item.data.source.toLowerCase() === 'load') {
        const load = {
          id: `${item.id.replace('-0', '-1')}`,
          type: 'custom',
          data: {
            sourcePos: 'left',
            percentage: porcDER,
            nodeType: 'piechart',
            source: 'load-pie',
          },
          position: {
            x: item.position.x + 120,
            y: item.position.y + 17,
          },
          child: false,
        };
        return [item, load];
      } else {
        return item;
      }
    })
    .flat();

  let loadSource = '';
  const dataEdges = newDataNodes.reduce((edges, node, index) => {
    if (node.data.source.toLowerCase() === 'load-pie') {
      loadSource = node.id;
    }

    if (index < newDataNodes.length - 1) {
      const source = newDataNodes[index + 1].child ? loadSource : node.id;
      const target = newDataNodes[index + 1].id;
      const edge = {
        id: `e${source}_${target}`,
        source,
        target,
        // type: 'smoothstep',
        // borderRadius: 0,
        pathOptions: { borderRadius: 0 },
        style: {
          stroke: '#868686',
          strokeDasharray: '2 2',
          // borderRadius: '50',
        },
      };
      edges.push(edge);
    }

    return edges;
  }, []);

  setEdges(dataEdges);
  setNodes(newDataNodes);
};

const SiteDiagram = ({ site, flags }) => {
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);

  const { isLoading, error, data } = useQuery({ ...getSitePowerCapacityQuery(site.uuid) });

  useEffect(() => {
    if (data && data.assemblies.length > 0) {
      generateNodes(data.assemblies, setNodes, setEdges, site?.timezone);
    } else {
      setNodes([]);
      setEdges([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (isLoading)
    return (
      <div className="site-diagram">
        <Box display="flex" flexDirection="row" alignItems="baseline">
          <Box>
            <Typography variant="h4" color="secondary">
              Site Overview
            </Typography>
          </Box>
        </Box>
        <Loading />
      </div>
    );
  if (error)
    return (
      <div className="site-diagram">
        <Box display="flex" flexDirection="row" alignItems="baseline">
          <Box>
            <Typography variant="h4" color="secondary">
              Site Overview
            </Typography>
          </Box>
        </Box>
        <DataComponentError />
      </div>
    );
  if (data) {
    return (
      <Box className="site-diagram" sx={{ width: '100%', height: '300px' }}>
        <Box display="flex" flexDirection="row" alignItems="baseline">
          <Box>
            <Typography variant="body2" color="secondary">
              Site Overview
            </Typography>
          </Box>
          <Box pl="2em">
            <Typography variant="subtitle1" color="grey">
              <TimeAgo date={data.last_update[0] + ' UTC'} />
            </Typography>
          </Box>
        </Box>
        <ReactFlowProvider>
          <FlowChild nodes={nodes} edges={edges} />
        </ReactFlowProvider>
      </Box>
    );
  }
};

const FlowChild = (nodes, edges) => {
  const snapGrid = [10, 10];
  const [flowInstance, setFlowInstance] = useState(null);
  const nodesInitialized = useNodesInitialized();
  useEffect(() => {
    if (flowInstance && nodesInitialized) {
      flowInstance.fitView({ minZoom: 1, maxZoom: 1 });
    }
  }, [flowInstance, nodesInitialized]);

  if (nodes?.nodes.length > 0) {
    return (
      <ReactFlow
        className="grey-background"
        style={{ top: '-40px' }}
        nodes={nodes.nodes}
        edges={nodes.edges}
        snapToGrid={true}
        snapGrid={snapGrid}
        nodeTypes={nodeTypes}
        elementsSelectable={false}
        nodesConnectable={false}
        nodesDraggable={false}
        zoomOnScroll={false}
        panOnScroll={false}
        panOnScrollMode={false}
        zoomOnDoubleClick={false}
        panOnDrag={false}
        zoomOnPinch={false}
        preventScrolling={false}
        onInit={setFlowInstance}
        onNodeMouseEnter={() => {}}
      />
    );
  }
};

SiteDiagram.propTypes = {
  site: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  error: PropTypes.object,
  flags: PropTypes.object,
};

export default SiteDiagram;
