import * as React from 'react';
import { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import useStore from './../state/KioskStore';

export default function KioskModeAlert() {
  const [isOpen, setIsOpen] = useState(false);
  const kioskStore = useStore((state) => state);

  useEffect(() => {
    if (kioskStore.mode === 'kiosk') {
      setIsOpen(true);
    }
  }, [kioskStore.mode]);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={isOpen}
      onClose={handleClose}
      message="Type “escape” key to view controls"
      autoHideDuration={4000}
    />
  );
}
