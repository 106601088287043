import React from 'react';
import { CSVLink } from 'react-csv';

import { getCSVData } from '../lib/DataUtil';
import useStore from '../state/KioskStore';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';

const CSVDownload = () => {
  const store = useStore((state) => state);

  const styles = {
    csvDownload: {
      backgroundColor: 'RGBA(255, 255, 255, 0.8)',
      '&:hover': {
        backgroundColor: 'RGBA(240, 240, 240, 0.8)',
      },
      color: '#E1E2E4',
    },
    csvIcon: { fontSize: '30px', color: '#939393' },
  };

  const getCSVName = () => {
    const siteName = store.siteMetadata.site_name;
    const timeRange = store.timeRangeKey;
    const siteNameFormatted = siteName.replace(/ /g, '_');
    return `${siteNameFormatted}_${timeRange}.csv`;
  };

  return (
    <CSVLink
      data={getCSVData(store.timeSeriesData)}
      filename={getCSVName()}
      target="_blank"
      rel="noreferrer"
      style={{ display: 'flex' }}
      data-testid="csv-download"
    >
      <Button sx={styles.csvDownload} variant="outlined" color="inherit">
        <DownloadIcon sx={styles.csvIcon} />
      </Button>
    </CSVLink>
  );
};

export default CSVDownload;
