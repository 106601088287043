import { Box, Card } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { useMobilePassThrough } from '../lib/StyleUtil';
import smsTheme from '../style/theme';
import {
  formatResourceKey,
  getAvailableResourceParams,
  getNumberedResourceRootNoAggregates,
} from './../lib/DataUtil';
import Metric from './Metric/Metric';

const filterGenset = (resources) => {
  // filter out Genset capacity if not in numbered resource mode
  const isNumberedResource = getNumberedResourceRootNoAggregates(resources);
  if (resources.some((resource) => resource.split('-')[0] === 'Genset') && !isNumberedResource) {
    resources = resources.filter((resource) => resource.split('-')[0] !== 'Genset');
  }
  return resources;
};

export const getMetricsParams = (metricsData, availableResources) => {
  // minimalist structure to render empty boxes while loading
  if (!metricsData) {
    return [
      {
        name: 'Row 1',
      },
      {
        name: 'Row 2',
      },
      {
        name: 'Row 3',
      },
    ];
  }
  availableResources = filterGenset(availableResources);

  const paramGetters = {
    Solar: (key) => ({
      name: `${formatResourceKey(key.split('-')[1])} Production`,
      value: metricsData[key.split('-')[1] + '_EnergyKwh'].val,
      indicatorMessage: 'Today So Far',
      iconUrl: '/icons/solar-production-icon.svg',
      mobileIconUrl: '/icons/solar-production-icon-mobile.svg',
      unit: 'kWh',
      highlightColor: smsTheme.palette.genSet.main,
      hasLiveData: metricsData[key.split('-')[1] + '_EnergyKwh'].hasLiveData,
      customStatusText: 'Today So Far',
    }),
    BESS: (key) => ({
      name: 'Battery Storage',
      value: metricsData.bessSoc.val,
      iconUrl: '/icons/battery-storage-icon.svg',
      mobileIconUrl: '/icons/battery-storage-icon-mobile.svg',
      unit: '%',
      highlightColor: smsTheme.palette.bess.main,
      capacityBarBackgroundColor: smsTheme.palette.bess.light,
      showCapacityPercentageBar: true,
      hasLiveData: metricsData.bessSoc.hasLiveData,
    }),
    Genset: (key) => ({
      name: `${formatResourceKey(key.split('-')[1])} Capacity`,
      value: metricsData[key.split('-')[1] + '_Capacity'].val,
      iconUrl: '/icons/generator-icon.svg',
      mobileIconUrl: '/icons/generator-icon-mobile.svg',
      unit: '%',
      highlightColor: smsTheme.palette.genSet.main,
      capacityBarBackgroundColor: smsTheme.palette.genSet.light,
      showCapacityPercentageBar: true,
      hasLiveData: metricsData[key.split('-')[1] + '_Capacity'].hasLiveData,
    }),
    Load: (key) => ({
      name: 'Site Consumption',
      value: metricsData[key.split('-')[1]].val,
      hasLiveData: metricsData[key.split('-')[1]].hasLiveData,
      iconUrl: '/icons/site-consumption-icon.svg',
      mobileIconUrl: '/icons/site-consumption-icon-mobile.svg',
      unit: 'kW',
      highlightColor: smsTheme.palette.genSet.main,
    }),
  };

  let metricsParams = getAvailableResourceParams(availableResources, paramGetters);

  const compactMode = metricsParams.length >= 4;

  metricsParams = metricsParams.map((param) => {
    return { ...param, compactMode };
  });

  return metricsParams;
};

const CumulativeMetrics = ({ metricsData, availableResources }) => {
  const theme = useTheme();
  const passThrough = useMobilePassThrough();

  const styles = {
    row: {
      display: 'flex',
      flex: 1,
      alignItems: 'stretch',
    },
    outerContainer: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      margin: 0,
      display: 'flex',
      gap: '.83vw',
      flex: 1,
    },
    card: {
      ...theme.classes.card,
      width: '100%',
      px: '2.1vw',
    },
  };

  return (
    <Box id="metrics-grid" sx={[styles.outerContainer, passThrough]}>
      {getMetricsParams(metricsData, availableResources).map((params) => (
        <Box key={params.name} sx={styles.row}>
          <Card sx={[styles.card, passThrough]} variant="outlined">
            {metricsData && <Metric {...params} />}
          </Card>
        </Box>
      ))}
    </Box>
  );
};

CumulativeMetrics.propTypes = {
  metricsData: PropTypes.object,
  todayTimeSeriesData: PropTypes.object,
  availableResources: PropTypes.array,
};

export default CumulativeMetrics;
